import { transform } from 'ol/proj';
import { ColorNames, colors as Colors } from '@/compositions/map/utils/data';

export const transformCoordinates = ([lon, lat]) =>
  transform([lon, lat], 'EPSG:4326', 'EPSG:3857');

export const transformCoordinatesToLonLat = ([x, y]) =>
  transform([x, y], 'EPSG:3857', 'EPSG:4326');

export const degToRad = rotation => Number((rotation * Math.PI) / 180);
export const radToDeg = rotation => Number((rotation * 180) / Math.PI) % 360;

export function genSvgGrad(colors, text, alarmed) {
  // array of codes
  const radius = 18;
  const part = 2 * Math.PI * radius;
  const count = colors.length;
  const result = colors.reduce((result, color, i) => {
    return `
      ${result}
      <circle
        r="${radius}" cx="50%" cy="50%"
        fill="transparent"
        stroke="${color}"
        stroke-width="20"
        style="stroke-dasharray: ${part / count}, ${(part / count) *
      (count - 1)}; stroke-dashoffset: ${(part / count) * i};"
      />
    `;
  }, '');

  return (
    'data:image/svg+xml;base64,' +
    window.btoa(`
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        ${result}
        <circle r="${radius}" cx="50%" cy="50%" fill="${
      alarmed ? Colors[ColorNames.red] : 'white'
    }" />
        <text x="50%" y="55%" fill="${
          alarmed ? 'white' : 'black'
        }" style="font-family: sans-serif; font-size: 1em;" dominant-baseline="middle" text-anchor="middle">${text}</text>
      </svg>
    `)
  );
}
