import { useAuth } from '@/modules/auth/api';

const { isLoggedIn, loadUser, token } = useAuth();

export async function loggedIn({ to, next, nextMiddleware }) {
  if (!isLoggedIn.value && to.name !== 'login') {
    if (token.value) {
      try {
        await loadUser();
      } catch {
        return next({ name: 'login' });
      }
    } else {
      return next({ name: 'login' });
    }
  }
  nextMiddleware();
}
