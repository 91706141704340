import VectorLayer from 'ol/layer/Vector';
import ModifyFeature from 'ol-ext/interaction/ModifyFeature';
import { del, ref, set } from 'vue-demi';
import VectorSource from 'ol/source/Vector';
import { getCenter } from 'ol/extent';
import { Feature } from 'ol';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import { asArray } from 'ol/color';
import { colors } from './utils/data';
import MultiPoint from 'ol/geom/MultiPoint';

export function useMapGeozones(map) {
  const selectedGeozoneIds = ref({});
  const editGeozonesState = ref({});
  const visibleGeozoneLayer = ref(true);

  const source = new VectorSource({
    features: []
  });

  function getStyle(feature) {
    const selected = feature.get('selected');
    const colorName = feature.get('color');
    const edit = feature.get('edit');
    const color = asArray(colors[colorName] || colors.default);
    const alphaColor = color.slice();
    alphaColor[3] = selected ? 0.5 : 0.3;
    const style = [
      new Style({
        fill: new Fill({ color: alphaColor }),
        stroke: new Stroke({ color: color, width: 1 })
      })
    ];
    if (edit) {
      style.push(
        new Style({
          image: new CircleStyle({
            radius: 3,
            fill: new Fill({
              color
            })
          }),
          geometry: function(feature) {
            var coordinates = feature.getGeometry().getCoordinates();
            return new MultiPoint(coordinates[0]);
          }
        })
      );
    }
    return style;
  }

  const geozoneLayer = new VectorLayer({
    source: source,
    style: getStyle,
    wrapX: false
  });

  const interaction = new ModifyFeature({
    sources: source
  });

  const updateEditGeozoneState = (geozoneId, { points }) => {
    editGeozonesState.value = {
      ...editGeozonesState.value,
      [geozoneId]: {
        points
      }
    };
  };

  interaction.on('modifyend', function(e) {
    // Rotation
    const geozoneId = e.features[0].getId();
    const points = e.features[0].getGeometry().getCoordinates();
    updateEditGeozoneState(geozoneId, {
      points
    });
  });

  interaction.setActive(false);
  map.addInteraction(interaction);

  const setMapToGeozoneCenter = geozoneId => {
    const geozone = source.getFeatureById(geozoneId);
    if (geozone) {
      map.getView().animate({
        center: getCenter(geozone.getGeometry().getExtent()),
        duration: 700
      });
      map.getView().fit(geozone.getGeometry(), {
        padding: [100, 100, 100, 100],
        duration: 700
      });
    }
  };

  const setGeozones = geozones => {
    console.log('set geozones');
    clearGeozones();
    source.addFeatures(
      geozones
        .filter(({ points }) => points?.length > 0 && Array.isArray(points[0]))
        .map(({ id, points, color }) => {
          if (!points?.length) return;
          const feature = new Feature({
            geometry: new Polygon(points)
          });
          feature.set('selected', false);
          feature.set('color', color);
          feature.setId(id.toString());
          return feature;
        })
    );
  };

  const selectFeature = (feature, selected) => {
    feature.set('selected', selected);
    selected
      ? set(selectedGeozoneIds.value, feature.getId(), true)
      : del(selectedGeozoneIds.value, feature.getId());
  };

  const selectGeozone = id => {
    const feature = source.getFeatureById(id);
    if (feature) {
      selectFeature(feature, true);
    }
  };

  const unselectAllGeozones = () => {
    Object.keys(selectedGeozoneIds.value).forEach(id => {
      const feature = source.getFeatureById(id);
      if (feature) {
        feature.set('selected', false);
      }
    });
    selectedGeozoneIds.value = {};
  };

  const selectGeozones = (ids = []) => {
    unselectAllGeozones();
    ids.forEach(id => {
      selectGeozone(id);
    });
  };

  const unselectGeozone = geozoneId => {
    const feature = source.getFeatureById(geozoneId);
    if (feature) {
      selectFeature(feature, false);
    }
  };

  const clearGeozones = () => {
    source.clear();
  };

  const enableEditGeozone = geozoneId => {
    interaction.setActive(true);
    const feature = source.getFeatureById(geozoneId);
    if (feature) {
      feature.set('edit', true);
    }
  };

  const disableEditGeozone = () => {
    interaction.setActive(false);
  };

  const getDefaultSize = () => {
    const extent = map.getView().calculateExtent(map.getSize());
    const geom = fromExtent(extent);
    geom.scale(0.8);
    return geom.getCoordinates();
  };

  const toggleVisibleGeozoneLayer = () => {
    visibleGeozoneLayer.value = !visibleGeozoneLayer.value;
    geozoneLayer.setVisible(visibleGeozoneLayer.value);
  };

  const setVisibleGeozoneLayer = visible => {
    visibleGeozoneLayer.value = visible;
    geozoneLayer.setVisible(visibleGeozoneLayer.value);
  };

  return {
    setGeozones,
    clearGeozones,
    geozoneLayer,
    selectGeozone,
    selectGeozones,
    unselectGeozone,
    unselectAllGeozones,
    enableEditGeozone,
    disableEditGeozone,
    setMapToGeozoneCenter,
    selectedGeozoneIds,
    editGeozonesState,
    getDefaultSize,
    toggleVisibleGeozoneLayer,
    visibleGeozoneLayer,
    setVisibleGeozoneLayer
  };
}
