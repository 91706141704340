import VectorLayer from 'ol/layer/Vector';
import ModifyFeature from 'ol-ext/interaction/ModifyFeature';
import { del, ref, set } from 'vue-demi';
import VectorSource from 'ol/source/Vector';
import { getCenter } from 'ol/extent';
import { Feature } from 'ol';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import { asArray } from 'ol/color';
import { ColorNames, colors } from './utils/data';
import MultiPoint from 'ol/geom/MultiPoint';
import { Translate } from 'ol/interaction';
import {
  transformCoordinates,
  transformCoordinatesToLonLat
} from '@/compositions/map/utils';
import Point from 'ol/geom/Point';

export function useMapGeotags(map) {
  const selectedGeotagIds = ref({});
  const editGeotagsState = ref({});
  const editBasePointState = ref({
    lat: 0,
    lon: 0
  });

  const source = new VectorSource({
    features: []
  });

  function getStyle(feature) {
    const selected = feature.get('selected');
    const colorName = feature.get('color');
    const hidden = feature.get('hidden');
    const edit = feature.get('edit');
    const color = asArray(colors[colorName] || colors.default);
    const alphaColor = color.slice();
    alphaColor[3] = hidden ? 0 : selected ? 0.5 : 0.3;
    const style = hidden
      ? [
          new Style({
            fill: new Fill({ color: alphaColor })
          })
        ]
      : [
          new Style({
            fill: new Fill({ color: alphaColor }),
            stroke: new Stroke({ color, width: 1 })
          })
        ];
    if (edit) {
      style.push(
        new Style({
          image: new CircleStyle({
            radius: 3,
            fill: new Fill({
              color
            })
          }),
          geometry: function(feature) {
            var coordinates = feature.getGeometry().getCoordinates();
            return new MultiPoint(coordinates[0]);
          }
        })
      );
    }
    return style;
  }

  const geotagLayer = new VectorLayer({
    source: source,
    style: getStyle,
    wrapX: false
  });

  const interaction = new ModifyFeature({
    sources: source
  });

  const updateEditGeotagState = (geotagId, { points }) => {
    editGeotagsState.value = {
      ...editGeotagsState.value,
      [geotagId]: {
        points
      }
    };
  };

  interaction.on('modifyend', function(e) {
    // Rotation
    const geotagId = e.features[0].getId();
    const points = e.features[0].getGeometry().getCoordinates();
    updateEditGeotagState(geotagId, {
      points
    });
  });

  interaction.setActive(false);
  map.addInteraction(interaction);

  const setMapToGeotagCenter = geotagId => {
    const geotag = source.getFeatureById(geotagId);
    if (geotag) {
      map.getView().animate({
        center: getCenter(geotag.getGeometry().getExtent()),
        duration: 1000
      });
      map.getView().fit(geotag.getGeometry(), {
        padding: [100, 100, 100, 100],
        duration: 700
      });
    }
  };

  const setGeotags = (geotags, hidden) => {
    console.log('set geotags');
    clearGeotags();
    source.addFeatures(
      geotags
        .filter(({ points }) => points?.length > 0 && Array.isArray(points[0]))
        .map(({ id, points, color }) => {
          if (!points?.length) return;
          const feature = new Feature({
            geometry: new Polygon(points)
          });
          feature.set('selected', false);
          feature.set('color', color);
          feature.set('hidden', hidden);
          feature.setId(id.toString());
          return feature;
        })
    );
  };

  const selectFeature = (feature, selected) => {
    feature.set('selected', selected);
    selected
      ? set(selectedGeotagIds.value, feature.getId(), true)
      : del(selectedGeotagIds.value, feature.getId());
  };

  const selectGeotag = id => {
    const feature = source.getFeatureById(id);
    if (feature) {
      selectFeature(feature, true);
    }
  };

  const unselectAllGeotags = () => {
    Object.keys(selectedGeotagIds.value).forEach(id => {
      const feature = source.getFeatureById(id);
      if (feature) {
        feature.set('selected', false);
      }
    });
    selectedGeotagIds.value = {};
  };

  const selectGeotags = (ids = []) => {
    unselectAllGeotags();
    ids.forEach(id => {
      selectGeotag(id);
    });
  };

  const unselectGeotag = geotagId => {
    const feature = source.getFeatureById(geotagId);
    if (feature) {
      selectFeature(feature, false);
    }
  };

  const clearGeotags = () => {
    source.clear();
  };

  const enableEditGeotag = geotagId => {
    interaction.setActive(true);
    const feature = source.getFeatureById(geotagId);
    if (feature) {
      feature.set('edit', true);
    }
  };

  const disableEditGeotag = () => {
    interaction.setActive(false);
  };

  const getDefaultSize = () => {
    const extent = map.getView().calculateExtent(map.getSize());
    const geom = fromExtent(extent);
    geom.scale(0.8);
    return geom.getCoordinates();
  };

  const updateBasePointState = latLon => {
    editBasePointState.value = latLon;
  };

  const basePointFeature = new Feature(new Point([0, 0]));

  const editBasePointSource = new VectorSource({
    features: [basePointFeature]
  });
  const basePointLayer = new VectorLayer({
    source: editBasePointSource,
    visible: false,
    style: new Style({
      image: new Icon({
        src: require('@/assets/base_point.png'),
        color: colors[ColorNames.default],
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 0.5
      })
    })
  });

  // map.addLayer(basePointLayer);

  const translateInteraction = new Translate({
    layers: [basePointLayer],
    hitTolerance: 48
  });

  translateInteraction.on(['translating', 'translateend'], e => {
    const feature = e.features.getArray()[0];
    if (feature) {
      console.log(feature.getGeometry().getCoordinates());
      const [lon, lat] = transformCoordinatesToLonLat(
        feature.getGeometry().getCoordinates()
      );
      console.log(lon, lat);
      updateBasePointState({
        lat,
        lon
      });
    }
  });

  map.addInteraction(translateInteraction);

  const enableEditBasePoint = ({ lat, lon }) => {
    translateInteraction.setActive(true);
    basePointLayer.setVisible(true);
    basePointFeature.setGeometry(new Point(transformCoordinates([lon, lat])));
  };

  const disableEditBasePoint = () => {
    translateInteraction.setActive(false);
    basePointLayer.setVisible(false);
  };

  return {
    setGeotags,
    clearGeotags,
    geotagLayer,
    selectGeotag,
    selectGeotags,
    unselectGeotag,
    unselectAllGeotags,
    enableEditGeotag,
    disableEditGeotag,
    setMapToGeotagCenter,
    selectedGeotagIds,
    editGeotagsState,
    getDefaultSize,
    enableEditBasePoint,
    disableEditBasePoint,
    editBasePointState,
    basePointLayer
  };
}
