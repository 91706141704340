import { NetworkStatus } from '@apollo/client/core';

export function promisifyQuery(load, onResult, onError) {
  return () =>
    new Promise((resolve, reject) => {
      load();
      onResult(({ loading, data, networkStatus }) => {
        if (!loading && networkStatus === NetworkStatus.ready) {
          resolve(data);
        }
      });
      onError(() => {
        reject();
      });
    });
}
