export function firstLetters(value) {
  if (!value) {
    return 'UN';
  }
  return value
    .split(' ')
    .map(i => i[0])
    .join('')
    .slice(0, 2);
}
