import { sortByDate } from '@/utils';
import { InMemoryCache } from '@apollo/client/cache';
import { processSubscriptionNode } from './utils';
import { relayStylePagination } from '@apollo/client/utilities';

export const cache = new InMemoryCache({
  // addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        notificationDeliveriesConnection: relayStylePagination([
          'filter',
          'orderBy'
        ]),
        objectPropertiesHistoriesConnection: relayStylePagination([
          'filter',
          ['propertyId']
        ]),
        controlExecutionsConnection: relayStylePagination(['filter'])
      }
    },
    Subscription: {
      fields: {
        Objects: processSubscriptionNode(),
        Schemas: processSubscriptionNode()
      }
    }
  }
});
