import { inject, provide, Ref, ref, watch } from '@vue/composition-api';
import router from '@/router';

export interface Popup {
  component?: any;
  props?: any;
  listeners?: any;
  attrs?: any;
}

export interface PopupStack {
  popups: Ref<Popup[]>;
  open: (popup: Popup) => void;
  openConfirm: (popup: Popup) => void;
  close: () => void;
  closeAll: () => void;
}

export const createPopupStack = (): PopupStack => {
  const popups = ref<Popup[]>([]);

  const defaultAttrs = {
    width: 420
  };

  const open = (popup: Popup) => {
    popups.value.push({
      attrs: { ...defaultAttrs, ...popup.attrs },
      ...popup
    });
  };

  const openConfirm = (popup: Popup) => {
    popups.value.push({
      attrs: { ...defaultAttrs, ...popup.attrs, preventFullscreen: true },
      ...popup
    });
  };
  const close = () => {
    popups.value.pop();
  };

  const closeAll = () => {
    popups.value = [];
  };

  const path = ref(router.currentRoute.path);
  router.afterEach(route => {
    path.value = route.path;
  });

  watch(
    () => path.value,
    () => {
      closeAll();
    }
  );
  return {
    popups,
    open,
    openConfirm,
    close,
    closeAll
  };
};

export const PopupProviderSymbol = Symbol('Popup identifier');

export const usePopupProvider = (): void => {
  provide(PopupProviderSymbol, createPopupStack());
};

export function usePopup(): PopupStack {
  return inject(PopupProviderSymbol) as PopupStack;
}
