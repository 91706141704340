import Vue from 'vue';
import VueRouter from 'vue-router';

import * as middlewares from './middlewares';
import beforeEach from './beforeEach';
import routes from './routes';
import qs from 'qs';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query, {
      encode: false,
      arrayFormat: 'indices'
    });
    return result ? '?' + result : '';
  }
});

router.beforeEach(beforeEach);

export default router;
export { middlewares };
