import DefaultLayout from '@/layouts/LayoutDefault.vue';
import { loggedIn } from './middlewares';

import auth from '@/modules/auth/ui/routes';
import objects from '@/modules/objects/ui/routes';
import landmarks from '@/modules/landmarks/ui/routes';
import geozones from '@/modules/geozones/ui/routes';
import geotags from '@/modules/geotags/ui/routes';
import plans from '@/modules/plans/ui/routes';
import types from '@/modules/object-types/ui/routes';
import notifications from '@/modules/notifications/ui/routes';
import connections from '@/modules/connections/ui/routes';

export default [
  {
    path: '',
    redirect: { name: 'objects' }
  },
  {
    path: '/',
    component: DefaultLayout,
    meta: {
      middleware: [loggedIn]
    },
    children: [
      {
        path: '',
        component: () => import('@/layouts/LayoutMap'),
        children: [
          ...objects,
          ...landmarks,
          ...geozones,
          ...geotags,
          ...plans,
          ...types,
          ...notifications,
          ...connections
        ]
      }
    ]
  },
  ...auth,
  {
    path: '*',
    redirect: {
      name: 'objects'
    }
  }
];
