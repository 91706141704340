import { computed, ref } from '@vue/composition-api';
import { apiClient, wsLink } from '@/provider';
import schemas from './schemas.graphql';
import { firstLetters as FL } from '@/utils';

const user = ref(null);
const loading = ref(false);
const isAppDataLoaded = ref(false);
const isAppDataLoading = ref(false);

export const useAuth = function() {
  const token = ref(null);

  token.value = localStorage.getItem('authToken');

  async function login(input) {
    const { data } = await apiClient.mutate({
      mutation: schemas.authRefreshToken,
      variables: { input }
    });

    const userRefreshToken = data.authRefreshToken.refreshToken.token;
    const tokenId = data.authRefreshToken.refreshToken.id;
    localStorage.setItem('tokenId', tokenId);
    localStorage.setItem('refreshToken', userRefreshToken);

    const res = await apiClient.mutate({
      mutation: schemas.authAccessToken,
      variables: {
        input: {
          userRefreshToken,
          accessTokenExpiration: 24 * 60,
          profileTags: ['application', 'monitor', 'user profile']
        }
      }
    });

    const jwt = res.data.authAccessToken.jwtToken;

    if (jwt) {
      localStorage.setItem('authToken', jwt);
      token.value = jwt;
    } else {
      throw new Error('User does not exist');
    }

    await loadUser();
  }

  function loginFromApp() {
    const refreshToken = localStorage.getItem('refreshToken');

    return apiClient
      .mutate({
        mutation: schemas.authAccessToken,
        variables: {
          input: {
            userRefreshToken: refreshToken,
            accessTokenExpiration: 24 * 60,
            profileTags: ['application', 'monitor', 'user profile']
          }
        }
      })
      .then(r => {
        const jwt = r.data.authAccessToken.jwtToken;
        if (jwt) {
          localStorage.setItem('authToken', jwt);
          token.value = jwt;
        } else {
          return Promise.reject(new Error('User does not exist'));
        }
      })
      .then(() => {
        loadUser();
      })
      .catch(e => {
        throw new Error(e);
      });
  }

  async function logout() {
    wsLink.client.dispose();
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenId');

    token.value = null;
    user.value = null;

    // await apiClient.resetStore();
    // use hard reload page because quite difficult to clear apollo store without any issues
    window.location = '/login';
  }

  function getUserId() {
    return apiClient
      .query({
        query: schemas.getUserId,
        fetchPolicy: 'no-cache'
      })
      .then(r => r.data.getUserId);
  }

  async function loadUser() {
    try {
      loading.value = true;
      const id = await getUserId();
      const { data } = await apiClient.query({
        query: schemas.loadUser,
        variables: {
          id
        },
        fetchPolicy: 'no-cache'
      });
      user.value = data.user;
    } finally {
      loading.value = false;
    }
  }

  function getUserProfileId() {
    return apiClient
      .query({
        query: schemas.getUserProfileId,
        fetchPolicy: 'no-cache'
      })
      .then(r => {
        if (r.data.getUserProfileId) {
          return r.data.getUserProfileId;
        }
        throw new Error('getUserProfileId does not exist');
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  const fullName = computed(() => user?.value?.login);
  const firstLetters = computed(() => FL(user?.value?.login));
  const email = computed(() => user?.value?.mEmail);
  const programName = computed(() => 'PixelMonitor');

  const isLoggedIn = computed(() => !!user.value);

  return {
    user,
    fullName,
    firstLetters,
    email,
    loading,
    loadUser,
    getUserId,
    token,
    login,
    logout,
    isLoggedIn,
    getUserProfileId,
    loginFromApp,
    programName,
    isAppDataLoaded,
    isAppDataLoading
  };
};
