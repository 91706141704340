import { useAuth } from '@/modules/auth/api';

const { isLoggedIn } = useAuth();

export async function notLoggedIn({ next, nextMiddleware }) {
  if (isLoggedIn.value) {
    return next({
      name: 'objects'
    });
  }
  return nextMiddleware();
}
