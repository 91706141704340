import { ref, computed, inject, provide } from '@vue/composition-api';
import baseItems from './baseMenuItems';
import { fillChildren } from './utils';
import useMenuObjectGroups from './useMenuObjectGroups';
import useMenuUserGroups from './useMenuUserGroups';
import { useObjectGroups } from '@/modules/object-groups/compositions/object-groups';
const mockUserGroups = ref([
  {
    id: '1',
    name: 'User group 1'
  },
  {
    id: '2',
    name: 'User group 2'
  }
]);

export const filterHideOnDesktop = (hide, isDesktop) => !!(isDesktop && hide);

export const createMenu = context => {
  const opened = ref(false);
  const baseMenuItems = ref(baseItems);

  const { objectGroupList, load } = useObjectGroups();
  const { items: objectGroupItems } = useMenuObjectGroups(objectGroupList);
  const { items: userGroupItems } = useMenuUserGroups(mockUserGroups);

  const isDesktop = computed(() => {
    return !context.root.$vuetify.breakpoint.mobile;
  });

  const menuItems = computed(() => {
    fillChildren('objects_groups', objectGroupItems.value, baseMenuItems);
    fillChildren('users_groups', userGroupItems.value, baseMenuItems);
    return baseMenuItems.value.filter(
      item => !filterHideOnDesktop(item.hideOnDesktop, isDesktop.value)
    );
  });

  const toggle = () => {
    opened.value = !opened.value;
  };

  return {
    opened,
    menuItems,
    toggle
  };
};

export const MenuProviderSymbol = Symbol('Menu identifier');

export const useMenuProvider = context => {
  provide(MenuProviderSymbol, createMenu(context));
};

export default function() {
  return inject(MenuProviderSymbol);
}
