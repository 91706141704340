<template>
  <v-app>
    <v-progress-linear v-if="isAppDataLoading" indeterminate />
    <slot />
    <popup-stack />
    <snack-bar />
  </v-app>
</template>

<script>
import PopupStack from './popup-stack';
import SnackBar from './snack-bar';
import { useAuth } from '@/modules/auth/api';
import { onMounted } from '@vue/composition-api';
export default {
  name: 'LayoutBase',
  components: {
    PopupStack,
    SnackBar
  },
  setup() {
    onMounted(() => {
      const wrapper = document.querySelector('.v-application--wrap');
      if (wrapper) {
        wrapper.style['min-height'] = `${window.innerHeight}px`;
      }
      window.addEventListener('resize', () => {
        wrapper.style['min-height'] = `${window.innerHeight}px`;
      });
    });

    return {
      isAppDataLoading: useAuth().isAppDataLoading
    };
  }
};
</script>

<style></style>
