import { apiClient } from '@/provider';
import typeScheme from './type.graphql';
import { trimFromStart } from '@/utils';

const SCHEME_NAME_PREFIX = 'PixelMonitor Object, ';
const addNamePrefix = name => `${SCHEME_NAME_PREFIX}${name}`;

export const trimName = schema => ({
  ...schema,
  name: trimFromStart(schema?.name, SCHEME_NAME_PREFIX)
});

export default {
  fetchBaseTypeSchemaId() {
    return apiClient
      .query({
        query: typeScheme.fetchBaseTypeSchemaId
      })
      .then(r => r.data.schemata[0]?.id || '');
  },
  async fetch(id) {
    const res = await apiClient.query({
      query: typeScheme.fetch,
      variables: {
        id
      },
      fetchPolicy: 'no-cache'
    });
    return trimName(res.data?.schema);
  },
  create({ name, schemaId }) {
    return apiClient
      .mutate({
        mutation: typeScheme.create,
        variables: {
          input: {
            newSchemaName: addNamePrefix(name),
            schemaId,
            newSchemaTags: ['application', 'monitor', 'object']
          }
        }
      })
      .then(r => r.data?.createSchemaCopy?.uuid);
  },

  update(id, { name, description }) {
    return apiClient.mutate({
      mutation: typeScheme.update,
      variables: {
        id,
        name: addNamePrefix(name),
        description
      }
    });
  },

  delete(id) {
    return apiClient.mutate({
      mutation: typeScheme.delete,
      variables: {
        id
      }
    });
  },

  enable(id) {
    return apiClient.mutate({
      mutation: typeScheme.enable,
      variables: {
        id
      }
    });
  },

  disable(id) {
    return apiClient.mutate({
      mutation: typeScheme.disable,
      variables: {
        id
      }
    });
  }
};
