import { computed, ref } from '@vue/composition-api';

export const sortTypes = {
  default: 'default',
  abc: 'abc',
  boolean: 'boolean',
  number: 'number'
};

export const abcSort = (a, b) => {
  if (!a || !b) return 0;
  if (a.toLowerCase() < b.toLowerCase()) return -1;
  if (a.toLowerCase() > b.toLowerCase()) return 1;
  return 0;
};

const booleanSort = (a, b) => (a === b ? 0 : a ? -1 : 1);

const numberSort = (a, b) => a - b;

const sortFunctions = {
  [sortTypes.abc]: abcSort,
  [sortTypes.number]: numberSort,
  [sortTypes.boolean]: booleanSort
};

export function useSortBy(
  items,
  initSortKey = '',
  initSortType = sortTypes.default
) {
  const sortType = ref(initSortType);
  const sortKey = ref(initSortKey);

  const sortedItems = computed(() => {
    if (sortType.value === sortTypes.default) {
      return items.value;
    }

    if (!sortKey) {
      console.warn("Sort key does't defined");
      return items.value;
    }

    if (!sortFunctions[sortType.value]) {
      console.warn('Unknown sort type');
      return items.value;
    }

    return [...items.value].sort((a, b) =>
      sortFunctions[sortType.value](a[sortKey.value], b[sortKey.value])
    );
  });

  return {
    sortedItems,
    sortType,
    sortKey
  };
}
