import { loggedIn } from '@/router/middlewares';

export default [
  {
    path: 'geotags',
    name: 'geotags',
    component: () => import(/* webpackChunkName: "main" */ './geotag-list'),
    meta: { middleware: [loggedIn] }
  },
  {
    path: 'geotags/:geotagId',
    name: 'geotag_card',
    props: true,
    meta: { middleware: [loggedIn] },
    component: () => import(/* webpackChunkName: "main" */ './geotag-card')
  },
  {
    path: 'geotags/:geotagId/change-form',
    name: 'geotag_change_form',
    props: true,
    meta: { middleware: [loggedIn] },
    component: () =>
      import(/* webpackChunkName: "main" */ './geotag-change-form')
  },
  {
    path: 'geotags/:geotagId/move-base',
    name: 'geotag_move_base',
    props: true,
    meta: { middleware: [loggedIn] },
    component: () => import(/* webpackChunkName: "main" */ './geotag-move-base')
  }
];
