import { loggedIn } from '@/router/middlewares';

export default [
  {
    path: 'geozones',
    name: 'geozones',
    component: () => import(/* webpackChunkName: "main" */ './geozone-list'),
    meta: { middleware: [loggedIn] }
  },
  {
    path: 'geozones/:geozoneId',
    name: 'geozone_card',
    props: true,
    meta: { middleware: [loggedIn] },
    component: () => import(/* webpackChunkName: "main" */ './geozone-card')
  },
  {
    path: 'geozones/:geozoneId/moving',
    name: 'geozone_moving',
    props: true,
    meta: { middleware: [loggedIn] },
    component: () => import(/* webpackChunkName: "main" */ './geozone-moving')
  }
];
