import { loggedIn } from '@/router/middlewares';

export default [
  {
    path: 'notifications',
    name: 'notifications',
    component: () =>
      import(/* webpackChunkName: "main" */ './notifications-list'),
    meta: { middleware: [loggedIn] }
  }
];
