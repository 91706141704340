import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import { OSM, XYZ } from 'ol/source';
import { ref } from '@vue/composition-api';

export const baseLayersDefinition = [
  {
    id: 'empty',
    name: 'No Map'
  },
  {
    id: 'osm',
    name: 'Open Street Map'
  },
  {
    id: 'google_satellite',
    name: 'Google satellite map'
  }
];

export function useBaseLayer() {
  const selectedBaseLayerId = ref(baseLayersDefinition[1].id);

  const emptyLayer = new TileLayer();
  emptyLayer.setProperties(baseLayersDefinition[1]);

  const osmLayer = new TileLayer({
    source: new OSM(),
    visible: true
  });
  osmLayer.setProperties(baseLayersDefinition[1]);

  const satellite = new TileLayer({
    source: new XYZ({
      url: 'http://mt{0-3}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
    }),
    visible: false
  });
  satellite.setProperties(baseLayersDefinition[2]);

  const layers = [emptyLayer, osmLayer, satellite];

  const baseGroupLayer = new LayerGroup({
    layers
  });

  const selectBaseLayer = id => {
    selectedBaseLayerId.value = id;
    layers.forEach(layer => {
      layer.setVisible(layer.get('id') === id);
    });
  };

  const setOSMTileUrl = url => {
    osmLayer.getSource().setUrl(url);
  };

  return {
    selectBaseLayer,
    baseGroupLayer,
    selectedBaseLayerId,
    setOSMTileUrl
  };
}
