import {
  flattenObject,
  patchObjectsByObjectId,
  SUB_EVENT_TYPES
} from '@/provider/utils';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { inject, provide } from 'vue-demi';
import connectionsSchema from '../api/connection-list.graphql';
import { promisifyQuery } from '@/utils';

export function createConnectionsStore() {
  const { subscribeToMore, result, load, onResult, onError } = useLazyQuery(
    connectionsSchema.load,
    {},
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const list = useResult(result, [], data =>
    data.objects.map(i => flattenObject(i))
  );

  subscribeToMore({
    document: connectionsSchema.listenConnectionList,
    variables: {},
    updateQuery: (previousResult, { subscriptionData }) => {
      console.log('connections list subscription');
      const relatedNode = subscriptionData.data?.Objects?.relatedNode;
      const eventType = subscriptionData.data?.Objects?.event;
      if (eventType !== SUB_EVENT_TYPES.insert) return;
      if (!relatedNode) return;
      switch (relatedNode.__typename) {
        case 'Object': {
          return {
            objects: [...previousResult.objects, relatedNode]
          };
        }
        case 'ObjectProperty': {
          const { objectId } = relatedNode;
          const patchedObjects = patchObjectsByObjectId(
            objectId,
            'objectProperties',
            relatedNode,
            previousResult.objects
          );

          if (patchedObjects) {
            return {
              objects: patchedObjects
            };
          }
        }
      }
    }
  });

  return {
    load: promisifyQuery(load, onResult, onError),
    list
  };
}

export const ConnectionsProviderSymbol = Symbol('connections identifier');

export const useConnectionsProvider = () => {
  provide(ConnectionsProviderSymbol, createConnectionsStore());
};

export function useConnections() {
  return inject(ConnectionsProviderSymbol);
}
