import { middlewares } from '@/router';

export default [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "auth" */ './AuthFormLogin.vue'),
    meta: {
      middleware: [middlewares.notLoggedIn]
    }
  },
  {
    path: '/from-app',
    name: 'from-app',
    component: () =>
      import(/* webpackChunkName: "auth" */ './LoginFromApp.vue'),
    meta: {
      middleware: [middlewares.notLoggedIn]
    }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () =>
      import(/* webpackChunkName: "auth" */ './AuthFormForgot.vue'),
    meta: {
      middleware: [middlewares.notLoggedIn]
    }
  },
  {
    path: '/enrollment',
    name: 'enrollment',
    component: () =>
      import(/* webpackChunkName: "auth" */ './AuthFormEnrollment.vue'),
    meta: {
      middleware: [middlewares.notLoggedIn]
    }
  },
  {
    path: '/password',
    name: 'password',
    component: () =>
      import(/* webpackChunkName: "auth" */ './AuthFormNewPassword.vue'),
    meta: {
      middleware: [middlewares.notLoggedIn]
    }
  }
];
