import { loggedIn } from '@/router/middlewares';

export default [
  {
    path: 'objects',
    component: () => import('./index.vue'),
    children: [
      {
        path: ':groupId?/list',
        name: 'objects',
        component: () => import('./object-list'),
        props: true,
        meta: {
          middleware: [loggedIn]
        }
      },
      {
        path: ':groupId?/list/moving',
        name: 'object_moving_group',
        props: true,
        meta: {
          middleware: [loggedIn]
        },
        component: () =>
          import(/* webpackChunkName: "main" */ './object-moving')
      },
      {
        path: ':groupId?/list/:objectId',
        name: 'object_card',
        component: () => import('./object-card'),
        props: true,
        meta: {
          middleware: [loggedIn]
        }
      },
      {
        path: ':groupId?/list/:objectId/moving',
        name: 'object_moving',
        props: true,
        meta: {
          middleware: [loggedIn]
        },
        component: () =>
          import(/* webpackChunkName: "main" */ './object-moving')
      }
    ]
  }
];
