import { loggedIn } from '@/router/middlewares';

export default [
  {
    path: 'settings/types',
    name: 'types',
    component: () => import(/* webpackChunkName: "main" */ './type-list'),
    meta: { middleware: [loggedIn] }
  },
  {
    path: 'settings/types/:typeId',
    name: 'type_card',
    props: true,
    meta: { middleware: [loggedIn] },
    component: () => import(/* webpackChunkName: "main" */ './type-card')
  }
];
