<template>
  <layout-base>
    <v-main>
      <router-view />
    </v-main>
    <error-notification />
    <app-menu />
  </layout-base>
</template>

<script>
import LayoutBase from './LayoutBase';
import AppMenu from './menu';
import ErrorNotification from '@/components/_base/ErrorNotification';

export default {
  name: 'LayoutDefault',
  components: {
    ErrorNotification,
    AppMenu,
    LayoutBase
  }
};
</script>
