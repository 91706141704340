import { loggedIn } from '@/router/middlewares';

export default [
  {
    path: 'landmarks',
    name: 'landmarks',
    component: () => import(/* webpackChunkName: "main" */ './landmark-list'),
    meta: { middleware: [loggedIn] }
  },
  {
    path: 'landmarks/:landmarkId',
    name: 'landmark_card',
    props: true,
    meta: { middleware: [loggedIn] },
    component: () => import(/* webpackChunkName: "main" */ './landmark-card')
  },
  {
    path: 'landmarks/:landmarkId/moving',
    name: 'landmark_moving',
    props: true,
    meta: { middleware: [loggedIn] },
    component: () => import(/* webpackChunkName: "main" */ './landmark-moving')
  }
];
