import { apiClient } from '@/provider';
import propertyScheme from './property.graphql';

export default {
  update(id, patch = {}) {
    return apiClient.mutate({
      mutation: propertyScheme.update,
      variables: {
        id,
        patch
      }
    });
  },
  updateValue(id, value) {
    return apiClient.mutate({
      mutation: propertyScheme.update,
      variables: {
        id,
        patch: {
          value: value
        }
      }
    });
  },
  updateValues(properties = []) {
    return apiClient.mutate({
      mutation: propertyScheme.updateManyById,
      variables: {
        properties: properties.map(property => ({
          propertyId: property.id,
          value: property.value
        }))
      }
    });
  }
};
