export function useEventHook() {
  const fns = [];

  function on(fn) {
    fns.push(fn);
    return {
      off: () => off(fn)
    };
  }

  function off(fn) {
    const index = fns.indexOf(fn);
    if (index !== -1) {
      fns.splice(index, 1);
    }
  }

  function trigger(param) {
    for (const fn of fns) {
      fn(param);
    }
  }

  return {
    on,
    off,
    trigger
  };
}
