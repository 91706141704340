<template>
  <div class="popup-stack">
    <popup
      v-for="(popup, idx) in popups"
      :key="idx"
      :value="idx === popups.length - 1"
      v-bind="popup.attrs"
      @input="!$event && close()"
    >
      <component
        :is="popup.component"
        v-bind="popup.props"
        @close="close"
        v-on="popup.listeners"
      />
    </popup>
  </div>
</template>

<script>
import Popup from '@/components/popup';
import { usePopup } from '@/compositions/popup';

export default {
  name: 'PopupStack',
  components: {
    Popup
  },
  setup() {
    const { popups, close } = usePopup();
    return {
      popups,
      close
    };
  }
};
</script>

<style></style>
