import {
  flattenObject,
  patchObjectsByObjectId,
  SUB_EVENT_TYPES
} from '@/provider/utils';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { inject, provide } from 'vue-demi';
import { mediaClient } from '@/provider';
import geozonesSchema from '../api/geozone-list.graphql';
import { useMap } from '@/compositions/map';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { computed } from '@vue/composition-api';
import { promisifyQuery } from '@/utils';

export function createGeozonesStore() {
  const {
    geozones: { selectedGeozoneIds }
  } = useMap();

  const { subscribeToMore, result, load, onResult, onError } = useLazyQuery(
    geozonesSchema.load,
    {},
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const { isCurrentGeotag } = useGeotags();

  const resultList = useResult(result, [], data =>
    data.objects.map(i => {
      const obj = flattenObject(i);
      return {
        ...obj,
        points: obj?.positionPoints || [],
        color: obj?.infoColor,
        url: mediaClient.getImageUrl(obj?.infoImageId),
        geotagIds: obj.objectsToObjectsByObject1Id.map(
          geotag => geotag.object2?.id
        )
      };
    })
  );

  const list = computed(() =>
    resultList.value.filter(object => {
      return (
        !!isCurrentGeotag([object.positionGeotagId, ...object.geotagIds]) ||
        selectedGeozoneIds.value[object.id]
      );
    })
  );

  subscribeToMore({
    document: geozonesSchema.listenGeozoneList,
    variables: {},
    updateQuery: (previousResult, { subscriptionData }) => {
      console.log('geozones list subscription');
      const relatedNode = subscriptionData.data?.Objects?.relatedNode;
      const eventType = subscriptionData.data?.Objects?.event;
      if (eventType !== SUB_EVENT_TYPES.insert) return;
      if (!relatedNode) return;
      switch (relatedNode.__typename) {
        case 'Object': {
          return {
            objects: [...previousResult.objects, relatedNode]
          };
        }
        case 'ObjectProperty': {
          const { objectId } = relatedNode;
          const patchedObjects = patchObjectsByObjectId(
            objectId,
            'objectProperties',
            relatedNode,
            previousResult.objects
          );

          if (patchedObjects) {
            return {
              objects: patchedObjects
            };
          }
        }
      }
    }
  });

  return {
    load: promisifyQuery(load, onResult, onError),
    list,
    resultList
  };
}

export const GeozonesProviderSymbol = Symbol('Geozones identifier');

export const useGeozonesProvider = () => {
  provide(GeozonesProviderSymbol, createGeozonesStore());
};

export function useGeozones() {
  return inject(GeozonesProviderSymbol);
}
