import { getCookie } from '@/utils/cookie';

const setup = (el, binding) => {
  let { value } = binding;

  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }

  el.setAttribute(`data-testid`, value);
};

export const testidPlugin = {
  install(Vue) {
    const isTestingMode = getCookie('app-test-data');

    if (!isTestingMode) {
      Vue.directive('testid', {});
      return;
    }

    Vue.directive('testid', {
      bind: setup,
      update: setup
    });
  }
};
