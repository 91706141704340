import { loggedIn } from '@/router/middlewares';

export default [
  {
    path: 'settings/plans',
    name: 'plans',
    component: () => import(/* webpackChunkName: "main" */ './plan-list'),
    meta: { middleware: [loggedIn] }
  },
  {
    path: 'settings/plans/:planId',
    name: 'plan_card',
    props: true,
    meta: { middleware: [loggedIn] },
    component: () => import(/* webpackChunkName: "main" */ './plan-card')
  },
  {
    path: 'settings/plans/:planId/moving',
    name: 'plan_moving',
    props: true,
    meta: { middleware: [loggedIn] },
    component: () => import(/* webpackChunkName: "main" */ './plan-moving')
  }
];
