import { prepareSchema, SUB_EVENT_TYPES } from '@/provider/utils';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import typesSchema from '../api/type-list.graphql';
import { trimName } from '@/modules/object-types/api/type';
import { inject, provide } from 'vue-demi';
import { promisifyQuery } from '@/utils';

export function createTypesStore() {
  const { subscribeToMore, result, load, onResult, onError } = useLazyQuery(
    typesSchema.load,
    {},
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const list = useResult(result, [], data => {
    return data.schemata
      .map(i => trimName(i))
      .map(i => {
        const defaultState = i.schemataByParentSchemaId?.find(schema =>
          schema.schemaProperties?.some(
            property =>
              property.key === 'stateIsDefault' &&
              property.defaultValue === true
          )
        );
        let color;
        let iconId;
        if (defaultState) {
          const preparedState = prepareSchema(defaultState);
          color = preparedState.stateColor?.value;
          iconId = preparedState.stateIcon?.value;
        }
        return {
          ...i,
          color,
          iconId
        };
      });
  });

  subscribeToMore({
    document: typesSchema.listenTypeList,
    variables: {},
    updateQuery: (previousResult, { subscriptionData }) => {
      console.log('types list subscription');
      const relatedNode = subscriptionData.data?.Schemas?.relatedNode;
      const eventType = subscriptionData.data?.Schemas?.event;
      if (eventType !== SUB_EVENT_TYPES.insert) return;
      if (!relatedNode) return;
      switch (relatedNode.__typename) {
        case 'Schema': {
          return {
            schemata: [...previousResult.schemata, relatedNode]
          };
        }
        case 'SchemaProperty': {
          const { schemaId } = relatedNode;
          const schemaIndex = previousResult.schemata.findIndex(
            schema => schema.id === schemaId
          );
          if (schemaIndex > -1) {
            const newObject = { ...previousResult.schemata[schemaIndex] };
            newObject.schemaProperties = [
              ...(previousResult.schemata[schemaIndex]?.schemaProperties || []),
              relatedNode
            ];
            const copyArray = previousResult.schemata.slice(0);
            copyArray.splice(schemaIndex, 1, newObject);
            return {
              schemata: copyArray
            };
          }
        }
      }
    }
  });

  return {
    load: promisifyQuery(load, onResult, onError),
    list
  };
}

export const TypesProviderSymbol = Symbol('Types identifier');

export const useTypesProvider = () => {
  provide(TypesProviderSymbol, createTypesStore());
};

export function useTypes() {
  return inject(TypesProviderSymbol);
}
