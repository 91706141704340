export function createCache() {
  let cache = {};

  const clearCache = () => {
    cache = {};
  };

  const get = id => {
    return cache[id];
  };

  const set = (key, value) => {
    cache[key] = value;
  };

  const has = key => {
    return !!cache[key];
  };

  const remove = key => {
    if (!has(key)) {
      return false;
    }

    delete cache[key];
    return true;
  };

  return {
    get,
    set,
    has,
    clearCache,
    cache
  };
}
