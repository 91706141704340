import { schemasService, objectService } from '.';
import { mediaClient } from '@/provider';

export default {
  async upload(file) {
    const schemas = await schemasService.fetch('MEDIA');
    const imageSchema = schemas.find(schema => schema.name === 'Media image');
    const schemaId = imageSchema?.id;
    if (!schemaId) {
      console.error(`Can't get media scheme id`);
      return;
    }
    const res = await objectService.create({ schemaId, name: file.name });
    const mediaId = res?.data?.createObject?.object?.id;
    if (!schemaId) {
      console.error(`Can't get media id`);
      return;
    }
    await mediaClient.upload(mediaId, file);
    return mediaId;
  }
};
