import { loggedIn } from '@/router/middlewares';

export default [
  {
    path: 'settings/connections',
    name: 'connections',
    component: () => import(/* webpackChunkName: "main" */ './connection-list'),
    meta: { middleware: [loggedIn] }
  },
  {
    path: 'settings/connections/:connectionId',
    name: 'connection_card',
    props: true,
    meta: { middleware: [loggedIn] },
    component: () =>
      import(/* webpackChunkName: "main" */ './connection-card/index.vue')
  }
];
