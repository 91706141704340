<template>
  <v-snackbar
    style="z-index: 9999"
    color="#D50000"
    :value="!!error"
    max-width="500"
    multiple
    :timeout="10000"
    @input="error = null"
  >
    <template v-slot>
      <div style="display: flex; align-items: center;">
        <div>
          {{ error }}
        </div>
        <v-btn class="ml-4" v-bind="$attrs" @click="error = null">
          Close
        </v-btn>
      </div>
    </template>
  </v-snackbar>
</template>

<script>
import useErrors from '@/compositions/errors';

export default {
  setup() {
    const { error } = useErrors();

    return {
      error
    };
  }
};
</script>
