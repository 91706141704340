import {
  flattenObject,
  patchObjectsByObjectId,
  SUB_EVENT_TYPES
} from '@/provider/utils';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { inject, provide, ref, computed } from 'vue-demi';
import { mediaClient } from '@/provider';
import geotagsSchema from '../api/geotag-list.graphql';
import { abcSort } from '@/compositions/sortBy';
import { promisifyQuery } from '@/utils';

export const ALL_GEOTAG_ID = '__all';
export const ALL_GEOTAG_ITEM = {
  id: ALL_GEOTAG_ID,
  name: 'All'
};

export function createGeotagsStore() {
  const defaultTags = ref([]);

  const { subscribeToMore, result, load, onResult, onError } = useLazyQuery(
    geotagsSchema.load,
    {},
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const list = useResult(result, [], data =>
    data.objects
      .map(i => {
        const obj = flattenObject(i);
        return {
          ...obj,
          points: obj?.positionPoints || [],
          url: mediaClient.getImageUrl(obj?.infoImageId)
        };
      })
      .sort((a, b) => abcSort(a.name, b.name))
  );

  const controlGeotagList = computed(() => [
    ...defaultTags.value,
    ...list.value
  ]);

  subscribeToMore({
    document: geotagsSchema.listenGeotagList,
    variables: {},
    updateQuery: (previousResult, { subscriptionData }) => {
      console.log('geotags list subscription');
      const relatedNode = subscriptionData.data?.Objects?.relatedNode;
      const eventType = subscriptionData.data?.Objects?.event;
      if (eventType !== SUB_EVENT_TYPES.insert) return;
      if (!relatedNode) return;
      switch (relatedNode.__typename) {
        case 'Object': {
          return {
            objects: [...previousResult.objects, relatedNode]
          };
        }
        case 'ObjectProperty': {
          const { objectId } = relatedNode;
          const patchedObjects = patchObjectsByObjectId(
            objectId,
            'objectProperties',
            relatedNode,
            previousResult.objects
          );

          if (patchedObjects) {
            return {
              objects: patchedObjects
            };
          }
        }
      }
    }
  });

  const getGeotagById = id => {
    return list.value.find(geotag => geotag.id === id);
  };

  const currentGeotag = ref('');

  const setGeotag = (id, resetToAllGeotag) => {
    const geotag = controlGeotagList.value.find(geotag => geotag.id === id);
    if (geotag) {
      currentGeotag.value = geotag;
      return;
    }
    if (resetToAllGeotag) {
      const allGeotag = controlGeotagList.value.find(
        geotag => geotag.id === ALL_GEOTAG_ID
      );
      if (allGeotag) {
        currentGeotag.value = allGeotag;
      }
    }
  };

  const currentGeotagExceptAllItem = computed(() => {
    if (currentGeotag.value.id !== ALL_GEOTAG_ID) {
      return currentGeotag.value;
    }
    return '';
  });

  const isCurrentGeotag = id => {
    if (currentGeotag.value.id === ALL_GEOTAG_ID) {
      return true;
    }

    let isCurrent;

    if (Array.isArray(id)) {
      isCurrent = id.includes(currentGeotag.value.id);
    } else {
      isCurrent = currentGeotag.value.id === id;
    }
    return isCurrent;
  };

  return {
    load: promisifyQuery(load, onResult, onError),
    list,
    getGeotagById,
    currentGeotag,
    setGeotag,
    isCurrentGeotag,
    controlGeotagList,
    ALL_GEOTAG_ID,
    currentGeotagExceptAllItem,
    defaultTags
  };
}

export const GeotagsProviderSymbol = Symbol('Geotags identifier');

export const useGeotagsProvider = () => {
  provide(GeotagsProviderSymbol, createGeotagsStore());
};

export function useGeotags() {
  return inject(GeotagsProviderSymbol);
}
