import { apiClient } from '@/provider';
import schemasScheme from './schemas.graphql';

export default {
  fetch(type = '', enabled, otherFilter = {}) {
    const variables = {};
    if (type) {
      variables.filter = {
        type: { equalTo: type },
        ...otherFilter
      };
    }
    if (enabled !== undefined) {
      variables.filter = {
        ...(variables.filter ? variables.filter : {}),
        enabled: { equalTo: enabled }
      };
    }
    return apiClient
      .query({
        query: schemasScheme.fetch,
        variables,
        fetchPolicy: 'no-cache'
      })
      .then(r => {
        return r.data.schemata;
      });
  },
  updateValues(properties = [], schemaId) {
    return apiClient.mutate({
      mutation: schemasScheme.updateManyById,
      variables: {
        schemaId,
        properties: properties.map(property => ({
          propertyId: property.id,
          value: property.value
        }))
      }
    });
  },
  delete(id) {
    return apiClient.mutate({
      mutation: schemasScheme.delete,
      variables: {
        id
      }
    });
  },
  deleteProperty(id) {
    return apiClient.mutate({
      mutation: schemasScheme.deleteProperty,
      variables: {
        id
      }
    });
  },
  updateProperty(
    id,
    {
      property,
      typeId,
      hidden,
      stealth,
      units,
      defaultValue,
      regex,
      valueRange,
      valueSet,
      mask
    }
  ) {
    return apiClient.mutate({
      mutation: schemasScheme.updateProperty,
      variables: {
        id,
        patch: {
          property,
          typeId,
          hidden,
          stealth,
          units,
          defaultValue,
          regex,
          valueRange,
          valueSet,
          mask
        }
      }
    });
  },
  createProperty({
    schemaId,
    property,
    typeId,
    hidden,
    stealth,
    units,
    defaultValue,
    regex,
    valueRange,
    valueSet,
    mask
  }) {
    return apiClient.mutate({
      mutation: schemasScheme.createProperty,
      variables: {
        input: {
          schemaId,
          property,
          typeId,
          hidden,
          stealth,
          units,
          groupName: 'Custom',
          defaultValue,
          regex,
          valueRange,
          valueSet,
          mask
        }
      }
    });
  },
  fetchProperty(id) {
    return apiClient
      .query({
        query: schemasScheme.fetchProperty,
        variables: { id },
        fetchPolicy: 'no-cache'
      })
      .then(r => {
        return r.data.schemaProperty;
      });
  }
};
