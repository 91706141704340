export const ColorNames = {
  default: 'default',
  purple: 'purple',
  blue: 'blue',
  green: 'green',
  yellow: 'yellow',
  orange: 'orange',
  grey: 'grey',
  red: 'red'
};

export const colors = {
  [ColorNames.default]: '#333333',
  [ColorNames.purple]: '#AA00FF',
  [ColorNames.blue]: '#2780E3',
  [ColorNames.green]: '#2E7D32',
  [ColorNames.yellow]: '#FFD600',
  [ColorNames.orange]: '#FF7518',
  [ColorNames.grey]: '#616161',
  [ColorNames.red]: '#D50000'
};
