export enum EntityEnum {
  ObjectGroup = 'ObjectGroup',
  Geotag = 'Geotag'
}

export const entityTags: Record<EntityEnum, Record<string, boolean>> = {
  ObjectGroup: {
    application: true,
    monitor: true,
    group: true
  },
  Geotag: {
    application: true,
    monitor: true,
    geotag: true
  }
};

export const checkEntityBySchemaTags = (
  entity: EntityEnum,
  schemaTags: string[]
): boolean => {
  return schemaTags.every(tag => entityTags[entity][tag]);
};
