<template>
  <div class="d-flex align-center mb-2 mx-5" style="min-height: 76px;">
    <v-img
      :key="1"
      contain
      position="center"
      max-height="76"
      class="mx-auto"
      :src="src"
    />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import useGeneralSettings from '@/modules/settings/general/useGeneralSettings';

export default {
  name: 'MenuItemLogo',
  setup(props, { root }) {
    const { logoUrl } = useGeneralSettings();
    return {
      src: computed(() => {
        if (!logoUrl.value) {
          return root.$vuetify.theme.dark
            ? require('@/assets/monitor_logo_dark.svg')
            : require('@/assets/monitor_logo_light.svg');
        }
        return logoUrl.value;
      })
    };
  }
};
</script>
