import { computed } from '@vue/composition-api';

export const BATTERY_STATUSES = {
  FULL: 'FULL',
  LOW: 'LOW',
  DC: 'DC',
  UNKNOWN: 'UNKNOWN'
};

export const ACTIVITY_STATUSES = {
  ACTIVE: 'ACTIVE',
  NO_RESPONSE: 'NO_RESPONSE',
  UNKNOWN: 'UNKNOWN'
};

export const DATA_STATUSES = {
  SET: 'SET',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  ERROR: 'ERROR'
};

export const GPS_STATUSES = {
  FIXED: 'FIXED',
  NOT_FIXED: 'NOT_FIXED',
  OFF: 'OFF'
};

export const ALARM_STATUSES = {
  OFF: 'OFF',
  ON: 'ON',
  TRIGGERED: 'TRIGGERED'
};

const dataIcons = {
  [DATA_STATUSES.PENDING]: {
    icon: '$status_requested'
  },
  [DATA_STATUSES.PROCESSING]: {
    icon: '$status_processing'
  },
  [DATA_STATUSES.ERROR]: {
    icon: '$status_error',
    color: 'red'
  }
};

const batteryIcons = {
  [BATTERY_STATUSES.FULL]: {
    icon: '$battery_full',
    color: undefined
  },
  [BATTERY_STATUSES.LOW]: {
    icon: '$battery_empty',
    color: 'red'
  },
  [BATTERY_STATUSES.DC]: {
    icon: '$battery_dc'
  },
  [BATTERY_STATUSES.UNKNOWN]: {
    icon: '$battery_unknown'
  }
};

const gpsIcons = {
  [GPS_STATUSES.FIXED]: {
    icon: '$gps_fixed'
  },
  [GPS_STATUSES.NOT_FIXED]: {
    icon: '$gps_not_fixed',
    color: 'red'
  }
};

const activityIcons = {
  [ACTIVITY_STATUSES.NO_RESPONSE]: {
    icon: '$status_no_response',
    color: 'red'
  },
  [ACTIVITY_STATUSES.UNKNOWN]: {
    icon: ''
  },
  [ACTIVITY_STATUSES.ACTIVE]: {
    icon: '$status_active'
  }
};

const alarmIcons = {
  [ALARM_STATUSES.ON]: {
    icon: '$alert'
  },
  [ALARM_STATUSES.TRIGGERED]: {
    icon: '$alert_on',
    color: 'red'
  }
};

export function useObjectStatus(object) {
  const batteryIcon = computed(() => {
    const batteryType =
      object?.value?.statusesBatteryType?.value ||
      object?.value?.statusesBatteryType;
    return batteryIcons[batteryType];
  });
  const showBatteryLevel = computed(() => {
    const batteryStatus =
      object?.value?.statusesBatteryType?.value ||
      object?.value?.statusesBatteryType;
    return !!(batteryIcon.value && batteryStatus !== BATTERY_STATUSES.DC);
  });
  {
    return {
      batteryIcon,
      showBatteryLevel,
      activityIcon: computed(() => {
        const status =
          object?.value?.statusesActivity?.value ||
          object?.value?.statusesActivity;
        return activityIcons[ACTIVITY_STATUSES[status]];
      }),
      alarmIcon: computed(() => {
        const alarmStatus =
          object?.value?.statusesAlarm?.value || object?.value?.statusesAlarm;
        return alarmIcons[alarmStatus];
      }),
      dataIcon: computed(() => {
        const dataStatus =
          object?.value?.statusesData?.value || object?.value?.statusesData;
        return dataIcons[dataStatus];
      }),
      gpsIcon: computed(() => {
        const gpsStatus =
          object?.value?.statusesGps?.value || object?.value?.statusesGps;
        return gpsIcons[gpsStatus];
      })
    };
  }
}
