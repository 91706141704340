<template>
  <v-hover v-slot:default="{ hover }" :key="item.title">
    <v-list-item
      exact
      :to="item.to"
      :disabled="item.disabled"
      @click="$emit('click', item)"
    >
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="menu-item subtitle-2">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item-content>

      <template v-if="item.actions && hover">
        <v-list-item-action
          v-for="itemAction in item.actions"
          :key="itemAction.id"
        >
          <v-icon
            class="ma-auto"
            icon
            small
            @click.stop.prevent="$emit('click', itemAction)"
            @mousedown.stop="() => {}"
            >{{ itemAction.icon }}</v-icon
          >
        </v-list-item-action>
      </template>
    </v-list-item>
  </v-hover>
</template>

<script>
export default {
  name: 'MenuItemDefault',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
