import { usePopup } from '@/compositions/popup';
import { computed } from 'vue-demi';

export default function(items) {
  const popup = usePopup();
  const addUserGroupItem = {
    id: 'add_objectgroup',
    title: 'Add group',
    icon: '$add',
    disabled: true,
    action: () => {
      popup.open({ component: () => import('@/modules/about/index.vue') });
    }
  };
  return {
    items: computed(() => [
      ...items.value.map(({ id, name }) => ({
        id,
        title: name,
        type: 'MenuItemDefault',
        disabled: true,
        to: {
          name: 'users',
          params: {
            groupId: id
          }
        },
        actions: [
          {
            id: 'edit',
            icon: '$edit',
            action: ({ popup }) => {
              popup.open({
                component: () => {},
                props: { id: id }
              });
            }
          },
          {
            id: 'delete',
            icon: '$delete',
            action: ({ popup }) => {
              popup.open({ component: {}, props: { id: id } });
            }
          }
        ]
      })),
      addUserGroupItem
    ])
  };
}
