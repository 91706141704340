import { useLazyQuery, useMutation, useResult } from '@vue/apollo-composable';
import schema from '@/modules/account/schema.graphql';
import { ref, computed } from '@vue/composition-api';
import { useAuth } from '@/modules/auth/api';
import { baseLayersDefinition } from '@/compositions/map/base-layer';

const programSettings = ref([
  {
    key: 'programDefaultPosition',
    value: [-0.12766, 51.507351]
  },
  {
    key: 'programDefaultRotation',
    value: 0
  },
  {
    key: 'programDefaultScale',
    value: 4
  },
  {
    key: 'programSizeOfObjectCardOnMap',
    value: 'medium'
  },
  {
    key: 'programSizeOfObjectsOnMap',
    value: 'medium'
  },
  {
    key: 'programMap',
    value: baseLayersDefinition[1].id // no map
  },
  {
    key: 'programAvailableMaps',
    value: { osm: true, google_satellite: true }
  },
  {
    key: 'programDefaultGeotag',
    value: ''
  },
  {
    key: 'programDefaultObjectGroup',
    value: ''
  },
  {
    key: 'programAllowAllGeotags',
    value: false
  },
  {
    key: 'programOsmUrl',
    value: ''
  }
]);

const useProgramSettings = (props, context) => {
  const { getUserProfileId } = useAuth();
  const userProfile = useLazyQuery(
    schema.loadProfile,
    {
      id: 0
    },
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only'
    }
  );

  userProfile.onResult(({ data }) => {
    if (data?.object) {
      data.object.objectProperties.forEach(item => {
        let target = programSettings.value.find(
          itemInner => item.key === itemInner.key
        );

        if (target && item.value) {
          target.value = item.value;

          if (item.key === 'programDefaultPosition') {
            target.value = item.value.split(',');
          }
        }
      });
    }
    programSettings.value = programSettings.value.slice();
  });

  const { mutate: updateObjectProperties } = useMutation(
    schema.updateObjectProperties
  );

  const userProfileProperties = useResult(
    userProfile.result,
    [],
    data => data.object?.objectProperties
  );

  const themes = [
    { id: 1, text: 'Classic', value: 'classic' },
    { id: 2, text: 'Dark', value: 'dark', disabled: true }
  ];
  const theme = ref('classic');

  const languages = [
    { id: 1, text: 'English', value: 'en' },
    { id: 2, text: 'Russian', value: 'ru', disabled: true }
  ];
  const language = ref({ id: 1, text: 'English', value: 'en' });

  const sizes = [
    { id: 1, text: 'Small', value: 'small' },
    { id: 2, text: 'Medium', value: 'medium' },
    { id: 3, text: 'Large', value: 'large' }
  ];

  const maps = computed(() => {
    const availableMaps = getById('programAvailableMaps') || {};
    return baseLayersDefinition.filter(
      map => !!availableMaps.value[map.id] || map.id === 'empty'
    );
  });

  const centerPosition = ref(['', '']);

  const setTheme = () => {
    context.root.$vuetify.theme.dark = theme.value === 'dark';
    localStorage.setItem('theme', theme.value);
  };

  const close = () => {
    context.emit('close');
  };
  const saveHandler = () => {
    setTheme();
    close();
  };

  const getById = key => {
    return programSettings.value.find(property => property.key === key);
  };

  const updateSettings = async () => {
    let propertiesArray = [];

    programSettings.value.forEach(item => {
      let propertyId = userProfileProperties.value.find(
        itemInner => itemInner.key === item.key
      ).id;

      const prevValue = userProfileProperties.value.find(
        itemProperty => itemProperty.key === item.key
      ).value;
      if (prevValue !== item.value) {
        let value = item.value;

        if (item.key === 'programDefaultPosition') {
          value = value.slice(0, 2).join(',');
        }

        if (item.key === 'programDefaultRotation') {
          value = Number(value);
        }

        propertiesArray.push({
          propertyId,
          value
        });
      }
    });

    if (propertiesArray.length) {
      return updateObjectProperties({ input: { propertiesArray } }).then(close);
    } else {
      return close();
    }
  };

  const load = async () => {
    userProfile.variables.value.id = await getUserProfileId();

    return new Promise((resolve, reject) => {
      if (userProfile.result.value) {
        userProfile.restart();
      } else {
        userProfile.load();
      }
      userProfile.onResult(() => resolve());
      userProfile.onError(() => reject());
    });
  };

  return {
    theme,
    themes,
    language,
    close,
    saveHandler,
    languages,
    sizes,
    centerPosition,
    userProfileProperties,
    programSettings,
    getById,
    updateSettings,
    maps,
    load
  };
};

export default useProgramSettings;
