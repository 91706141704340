import Vue from 'vue';

Vue.component('FormSubheader', () => import('./_base/FormSubheader'));
Vue.component('FormSwitcher', () => import('./_base/FormSwitcher'));
Vue.component('FormRadio', () => import('./_base/FormRadio'));
Vue.component('FormRadioGroup', () => import('./_base/FormRadioGroup'));
Vue.component('FormItemValue', () => import('./_base/FormItemValue'));
Vue.component('FormDatePicker', () => import('./_base/FormDatePicker'));
Vue.component('FormTimePicker', () => import('./_base/FormTimePicker'));
Vue.component('FormText', () => import('./_base/FormText'));
Vue.component('FormColorSelector', () => import('./_base/FormColorSelector'));
Vue.component('FormIconSelector', () => import('./_base/FormIconSelector'));
Vue.component('ImageUploader', () => import('./_base/ImageUploader'));
Vue.component('DropdownMenu', () => import('./_base/DropdownMenu'));
Vue.component('DropdownMenuSelector', () =>
  import('./_base/DropdownMenuSelector')
);
Vue.component('DropdownMenuItemDefault', () =>
  import('./_base/DropdownMenuItemDefault')
);
Vue.component('DropdownMenuItemCheck', () =>
  import('./_base/DropdownMenuItemCheck')
);
Vue.component('DropdownMenuItemDivider', () =>
  import('./_base/DropdownMenuItemDivider')
);
Vue.component('DropdownMenuItemHeader', () =>
  import('./_base/DropdownMenuItemHeader')
);
Vue.component('FavouriteIcon', () => import('./_base/FavouriteIcon'));
Vue.component('BaseList', () => import('./_base/BaseList'));
Vue.component('BaseVirtualList', () => import('./_base/BaseVirtualList'));
Vue.component('ListSubheader', () => import('./_base/ListSubheader'));
Vue.component('ListItem', () => import('./_base/ListItem'));
Vue.component('ListItemInfo', () => import('./_base/ListItemInfo'));
Vue.component('ListItemSelectable', () => import('./_base/ListItemSelectable'));
Vue.component('ListItemChips', () => import('./_base/ListItemChips'));
Vue.component('ListItemMultiline', () =>
  import('./_base/ListItemMultiline.vue')
);
Vue.component('ListButton', () => import('./_base/ListButton.vue'));
Vue.component('ListItemLoading', () => import('./_base/ListItemLoading.vue'));
Vue.component('ListItemMessage', () => import('./_base/ListItemMessage.vue'));
Vue.component('Popup', () => import('./popup'));
Vue.component('HistoryStack', () => import('./history-stack'));
Vue.component('HistoryListCard', () => import('./history-list-card'));
Vue.component('PopupLayoutDefault', () =>
  import('./popup/PopupLayoutDefault.vue')
);
