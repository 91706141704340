import { inject, provide, ref } from '@vue/composition-api';

export const SNACK_TYPES = {
  INFO: 'INFO',
  ERROR: 'ERROR'
};

const snackBarOptions = {
  [SNACK_TYPES.INFO]: {
    color: 'primary'
  }
};

export const createSnackBar = () => {
  const showed = ref(false);
  const text = ref('');
  const options = ref({});

  const defaultOptions = {
    type: SNACK_TYPES.INFO,
    timeout: 3000
  };

  const show = (message, options = defaultOptions) => {
    showed.value = true;
    options.value = {
      ...defaultOptions,
      ...options
    };
    text.value = message;
  };
  const hide = () => {
    showed.value = false;
    text.value = '';
    options.value = {};
  };
  return {
    show,
    hide,
    options,
    text,
    showed
  };
};

export const SnackBarProviderSymbol = Symbol('Snackbar identifier');

export const useSnackBarProvider = () => {
  provide(SnackBarProviderSymbol, createSnackBar());
};

export function useSnackBar() {
  return inject(SnackBarProviderSymbol);
}
