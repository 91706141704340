import {
  flattenObject,
  patchObjectsByObjectId,
  SUB_EVENT_TYPES
} from '@/provider/utils';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { inject, provide } from 'vue-demi';
import { mediaClient } from '@/provider';
import plansSchema from '../api/plan-list.graphql';
import { useMap } from '@/compositions/map';
import { debounce } from 'throttle-debounce';
import { watch, computed } from '@vue/composition-api';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { abcSort } from '@/compositions/sortBy';
import { promisifyQuery } from '@/utils';

export function createPlansStore() {
  const {
    plans: { showedPlanIds, setPlans, showPlans, selectedPlanIds }
  } = useMap();
  const { subscribeToMore, result, load, onResult, onError } = useLazyQuery(
    plansSchema.load,
    {},
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const { isCurrentGeotag } = useGeotags();

  const resultList = useResult(result, [], data =>
    data.objects
      .map(i => flattenObject(i))
      .map(i => ({
        ...i,
        url: mediaClient.getImageUrl(i.imageId),
        geotagIds: i.objectsToObjectsByObject1Id.map(
          geotag => geotag.object2?.id
        )
      }))
  );

  const list = computed(() =>
    resultList.value
      .filter(plan => {
        return (
          !!isCurrentGeotag([plan.positionGeotagId, ...plan.geotagIds]) || // filter by linked geotags and geotag in position
          selectedPlanIds.value.some(planId => planId === plan.id)
        );
      })
      .sort((a, b) => abcSort(a.name, b.name))
  );

  const enabledPlans = computed(() =>
    list.value.filter(plan => !!plan.enabled)
  );

  const throttleUpdate = debounce(1000, plans => {
    setPlans(
      plans.map(i => ({
        ...i,
        width: i.positionWidth,
        height: i.positionHeight,
        rotation: i.positionRotation,
        center: [i.positionCenter.lon, i.positionCenter.lat],
        visible: !!i.infoDisplayByDefault
      }))
    );
  });

  watch(
    () => list.value,
    plans => {
      throttleUpdate(plans);
    }
  );

  watch(
    () => showedPlanIds.value,
    planIds => {
      showPlans(planIds);
    },
    {
      immediate: true
    }
  );

  subscribeToMore({
    document: plansSchema.listenPlanList,
    variables: {},
    updateQuery: (previousResult, { subscriptionData }) => {
      console.log('plans list subscription');
      const relatedNode = subscriptionData.data?.Objects?.relatedNode;
      const eventType = subscriptionData.data?.Objects?.event;
      if (eventType !== SUB_EVENT_TYPES.insert) return;
      if (!relatedNode) return;
      switch (relatedNode.__typename) {
        case 'Object': {
          return {
            objects: [...previousResult.objects, relatedNode]
          };
        }
        case 'ObjectProperty': {
          const { objectId } = relatedNode;
          const patchedObjects = patchObjectsByObjectId(
            objectId,
            'objectProperties',
            relatedNode,
            previousResult.objects
          );

          if (patchedObjects) {
            return {
              objects: patchedObjects
            };
          }
        }
      }
    }
  });

  return {
    load: promisifyQuery(load, onResult, onError),
    list,
    resultList,
    enabledPlans
  };
}

export const PlansProviderSymbol = Symbol('Plans identifier');

export const usePlansProvider = () => {
  provide(PlansProviderSymbol, createPlansStore());
};

export function usePlans() {
  return inject(PlansProviderSymbol);
}
